import request from "../composables/useHttp";


/**
 * @description: 获取banner图
 * @params: {number} bannerTypeId > 1:首页； 2：关于我们； 3：新闻中心；   4：企业文化； 5：业务体系； 6：党群建设； 7：人才发展, 8:信息公开
 * */
export function getBanner(bannerTypeId) {
    return request("/banner-manage/gw/findGWBannerById", {
        method: 'get',
        params: {bannerTypeId}
    })
}


/**
 * @description: 获取新闻列表
 * */
export function getNewsList(params) {
    return request("/news/gw/findGWNews", {
        params
    })
}


/**
 * @description: 获取公告
 * @param: {number} typeId > 1：通知公告；    2：公示公告
 * */
export function getAnnouncements(typeId) {
    return request("/info-notice/gw/findGWInfoNotices", {
        method: 'get',
        params: {typeId}
    })
}


/**
 * @description: 获取顶部公共导航
 * */
export function getNav() {
    return request("/special-topic/gw/findGWNavigation")
}
/**
 * @description: 搜索
 * */
export function siteSearchIndex(params) {
    return request("/siteSearchIndex/findContent", {
        params
    })
}

/**
 * @description: 获取视频
 * */
export function getVideo() {
    return request("/society-duty/gw/findGWSocietyDuty")
}